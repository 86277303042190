html {
  background-color: orange;
}

.row {
  text-align: center;
}

h2 {
  font-size: 40px;
}

h1 {
  font-size: 50px;
  font-weight: bolder;
  font-family: 'Courier New', Courier, monospace;
}


.transparency {
  width: 100%;
  border-radius: 10px;
  background: transparent;
}

.h1 {
  font-size: 5rem;
  margin-left: 13%;
  margin-bottom: 0;
}

.container {
  padding-top: 0;
}

.sample {
  background-color: rgba(243, 216, 150, 0.8);
  font-size: 22px;
  border-radius: 15px;
  height: 400px;
  padding: 0 5px 0 5px;
}

.wpm, .accuracy {
  border-radius: 8px;
  font-size: 20px;
  font-weight: bolder;
  padding: 1%;
  height: 15%;
}

.btn {
  margin-top: 15%;
}

#root {
  display: flex;
  justify-content: center;
  background: transparent;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.data {
  display: flex;
  justify-content: space-around;
  margin: 0 0 0.8rem 0;
}

.dataButtons {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
}

.mod {
  position: fixed;
  z-index: 20;
  width: 50%;
  height: 70%;

  /* Center the modal */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgb(241, 168, 31)
}

.mod-header {
  display: flex;
  justify-content: space-between;
}

.title {
  margin-top: 5%;
  font-size:xx-large;
}

.close-button {
  border: none;
  background: transparent;
  cursor: pointer;
  color: rgb(168, 52, 52);
  font-size: 36px;
  margin-left: 95%;
}

.close-button:hover {
  color: rgb(85, 85, 10);
}

.modal-bgr {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 20;
  background-color: rgba(21, 13, 13, 0.7);

  /* Center the modal */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.cat {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.boo {
  z-index: 100;
	position: absolute;
}

.pic {
  margin-left: 7%;
}