.mod1 {
    position: fixed;
    z-index: 20;
    width: 40%;
    height: 100%;

  /* Center the modal */
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: rgb(241, 168, 31);

    overflow-y: scroll;

    scroll-behavior: auto;
}